const black = "#000000"
const white = "#FFFFFF"
export const main = process.env.GATSBY_COLOR_MAIN || "#C1E6FB"
const primary = "#0D2531"
export const secondary1 = process.env.GATSBY_COLOR_SECONDARY_1 || "#FFFFFF"
const secondary2 = "#8B8FA1"
export const secondary3 = process.env.GATSBY_COLOR_SECONDARY_3 || "#004A55"
const tertiary1 = process.env.GATSBY_COLOR_TERTIARY_1 || "#D6021D"
const tertiary2 = process.env.GATSBY_COMPANY_TERTIARY_2 || "#00C800"
const tertiary3 = process.env.GATSBY_COMPANY_TERTIARY_3 || "#3971B4"
const tertiary4 = process.env.GATSBY_COMPANY_TERTIARY_4 || "#F5A623"
export const ctaMain = process.env.GATSBY_CTA_MAIN || "#004A55"
export const ctaSecondaryOne = process.env.GATSBY_CTA_SECONDARY_ONE || "#015A5B"
export const ctaSecondaryTwo = process.env.GATSBY_CTA_SECONDARY_TWO || "#026653"
export const ctaSecondaryThree =
  process.env.GATSBY_CTA_SECONDARY_THREE || "#047242"
export const ctaLight = process.env.GATSBY_CTA_LIGHT || "#FAECE8"
export const borderMedium = process.env.GATSBY_BORDER_MEDIUM || "#9ED6FC"
const ctaInactive = "#E7ECF0"
const errorDark = "#B21024"
const errorMedium = "#DC142D"
const errorLight = "#FEF4F5"
const successDark = "#006800"
const successMedium = "#008600"
const successLight = "#EFFFEF"
const dividerLight = "#E7ECF0"
const transparent = "transparent"
const greyBorder = "#e2e2e2"
const whiteHover = "#F6F6F6"

export const colors = {
  black,
  white,
  main,
  primary,
  secondary1,
  secondary2,
  secondary3,
  tertiary1,
  tertiary2,
  tertiary3,
  tertiary4,
  ctaMain,
  ctaSecondaryOne,
  ctaSecondaryTwo,
  ctaSecondaryThree,
  ctaLight,
  borderMedium,
  ctaInactive,
  errorDark,
  errorLight,
  errorMedium,
  successDark,
  successMedium,
  successLight,
  dividerLight,
  transparent,
  greyBorder,
  whiteHover,
}
