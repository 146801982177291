import moment, { Moment } from "moment"
import { Day, RegularOpeningHours } from "../models/restaurant"

type OpeningHour = { open: Moment; close: Moment }

export type ModifiedRestaurantOpeningHours = {
  day: Moment
  openingHours: OpeningHour[]
}[]

export const getBaseOpeningHoursArray = (): ModifiedRestaurantOpeningHours => {
  return [0, 1, 2, 3, 4, 5, 6].map((i) => ({
    day: moment().add(i, "day").startOf("day"),
    openingHours: [] as OpeningHour[],
  }))
}

export const getOpeningsHoursBeforeMidnightForDay = (
  day: Moment,
  regularOpeningHours: RegularOpeningHours
): OpeningHour | undefined => {
  const writtenDay = day.locale("en").format("dddd").toLowerCase() as Day
  const dayOpeningHours = regularOpeningHours[writtenDay]

  // If no opening hours are set for the day
  if (Object.keys(dayOpeningHours).length === 0) {
    return undefined
  }

  // Initially set as open all day
  let openingMoment = day.clone().startOf("day")
  let closingMoment = day.clone().endOf("day").second(0).millisecond(0)

  // Replace close time with close time provided by API
  if (dayOpeningHours.close) {
    const [closingHour, closingMinute] = dayOpeningHours.close.split(":")
    closingMoment.hour(Number(closingHour)).minute(Number(closingMinute))
  }

  // Replace open time with open time provided by API
  if (dayOpeningHours.open) {
    const [openingHour, openingMinute] = dayOpeningHours.open.split(":")
    openingMoment.hour(Number(openingHour)).minute(Number(openingMinute))
  }

  // If the closing time is before the opening time, it means the closing time is the next day
  // Therefor setting to 23:59 and let getOpenignHoursAfterMidnightFromTheDayBefore handle the rest
  if (closingMoment.isBefore(openingMoment)) {
    closingMoment.hour(23).minute(59)
  }

  return { close: closingMoment, open: openingMoment }
}

export const getModifiedOpeningHoursAfterMidnightFromTheDayBefore = (
  day: Moment,
  regularOpeningHours: RegularOpeningHours
): OpeningHour | undefined => {
  const momentDayBefore = day.clone().subtract(1, "day")
  const writtenDayBefore = momentDayBefore
    .locale("en")
    .format("dddd")
    .toLowerCase() as Day
  const dayBeforeOpeningHours = regularOpeningHours[writtenDayBefore]

  // If no opening hours are set for the day
  if (Object.keys(dayBeforeOpeningHours).length === 0) {
    return undefined
  }

  // If day before has no open or close hours return undefined
  if (!dayBeforeOpeningHours.close || !dayBeforeOpeningHours.open) {
    return undefined
  }

  const [closingHour, closingMinute] = dayBeforeOpeningHours.close.split(":")
  const [openingHour, openingMinute] = dayBeforeOpeningHours.open.split(":")
  const closingMoment = momentDayBefore
    .clone()
    .startOf("day")
    .hour(Number(closingHour))
    .minute(Number(closingMinute))
  const openingMoment = momentDayBefore
    .clone()
    .startOf("day")
    .hour(Number(openingHour))
    .minute(Number(openingMinute))

  // If open hour is before close hour it means no opening hours after midnight
  if (openingMoment.isBefore(closingMoment)) {
    return undefined
  }

  // Else we return the opening hours after midnight
  return {
    close: closingMoment.add(1, "day"),
    open: day.clone().startOf("day"),
  }
}

export const getModifiedOpeningHours = (
  regularOpeningHours: RegularOpeningHours
) => {
  const baseOpeningHoursArray = getBaseOpeningHoursArray()
  baseOpeningHoursArray.forEach((oh) => {
    const openingHoursAfterMidnightDayBefore =
      getModifiedOpeningHoursAfterMidnightFromTheDayBefore(
        oh.day,
        regularOpeningHours
      )
    const openingHoursTheDayOf = getOpeningsHoursBeforeMidnightForDay(
      oh.day,
      regularOpeningHours
    )
    if (openingHoursAfterMidnightDayBefore) {
      oh.openingHours.push(openingHoursAfterMidnightDayBefore)
    }
    if (openingHoursTheDayOf) {
      oh.openingHours.push(openingHoursTheDayOf)
    }
  })

  return baseOpeningHoursArray
}
