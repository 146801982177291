import { CheckoutOrder } from "../models/order"

export const setCachedOrder = (order: CheckoutOrder, cartId: string) => {
  localStorage.setItem(
    "hg::cache::order",
    JSON.stringify({
      id: order.order.id,
      cart_id: cartId,
      _links: order.order._links,
    })
  )
}

export const removeCachedOrder = () => {
  localStorage.removeItem("hg::cache::order")
}

export const getCachedOrderId = () => {
  const cachedOrderId = localStorage.getItem("hg::cache::order")
    ? (JSON.parse(localStorage.getItem("hg::cache::order")!) as {
        id: string
      })!.id
    : undefined
  return cachedOrderId
}
