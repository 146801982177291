import styled from "@emotion/styled"
import React from "react"
import { Text } from "rebass"

const StyledText = styled(Text)`
  font-family: SFReg;
  display: -webkit-box;

  ${(props) => !props.fontSize && "font-size: 17px;"}
  ${(props) =>
    props.showEllipsis &&
    `
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
    
    ${(props) => {
    switch (true) {
      case props.color:
        return `color: ${props.color}`
      case props.primary:
        return `color: ${props.theme.colors.primary}`
      case props.black:
        return `color: ${props.theme.colors.black}`
      default:
        return `color: ${props.theme.colors.secondary3}`
    }
  }}

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary3};
    font-weight: 600;
  }
`

const Paragraph = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>
}

export default Paragraph
