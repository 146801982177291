import React, {
  Context,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  useGuestSignin,
  useSignin,
  useSignout,
  useSignup,
} from "../services/queries"
import { AccountSession } from "../models/user"
import StoreContext from "./store-context"
import { useAuthModals } from "./AuthModalsContext"
import { AuthenticateRequest } from "../models/email-authenticate-request"
import { SignupPayload } from "../models/sign-up-form"
import moment from "moment"
import { removeCachedOrder } from "../services/cached-order"

type AccountContextType = {
  accountType: "user" | "guest"
  signinGuestAndClearCaches: () => void
  signinUser: (payload: AuthenticateRequest) => void
  signoutAndClearCaches: () => void
  signupAndSignin: (payload: SignupPayload) => void
}

type DefaultAccountContextType = {
  accountType?: "user" | "guest"
  signinGuestAndClearCaches: () => void
  signinUser: (payload: AuthenticateRequest) => void
  signoutAndClearCaches: () => void
  signupAndSignin: (payload: SignupPayload) => void
}

const defaultContext: DefaultAccountContextType = {
  accountType: undefined,
  signinGuestAndClearCaches: () => {},
  signinUser: () => {},
  signoutAndClearCaches: () => {},
  signupAndSignin: () => {},
}

export const AccountContext = createContext(defaultContext)

type Props = {
  children: React.ReactNode
}

export const AccountProvider = ({ children }: Props) => {
  const [accountType, setAccountType] = useState<"user" | "guest">("guest")

  const { clearCart, setDeliveryAddress } = useContext(StoreContext)
  const { setVisibleAuthModal } = useAuthModals()

  const { mutateAsync: guestSignin } = useGuestSignin()
  const { mutateAsync: signin } = useSignin()
  const { mutateAsync: signout } = useSignout()
  const { mutateAsync: signup } = useSignup()

  const signinGuestAndClearCaches = async () => {
    clearCart()
    localStorage?.removeItem("hg::cache::user")
    localStorage?.removeItem("hg::cache::cart")
    localStorage?.removeItem("hg::cache::order")

    const { data: accountSession } = await guestSignin()
    setAccountType("guest")
    localStorage?.setItem("hg::cache::user", JSON.stringify(accountSession))
  }

  const signinUser = async (payload: AuthenticateRequest) => {
    const { data } = await signin(payload)
    removeCachedOrder() // TODO: existing guest order cannot be transfered to user
    setDeliveryAddress(null) // TODO: Unite cache clearance in its own function. Refactor clearCart function from StoreContext
    setAccountType("user")
    localStorage?.setItem("hg::cache::user", JSON.stringify(data))
    window?.analytics?.identify(data.mixpanel_id)
    window?.analytics?.track("Login successful", { type: "email" })
    window?.analytics?.alias(data.mixpanel_id)
    setVisibleAuthModal(undefined)
  }

  const signoutAndClearCaches = async () => {
    await signout()
    window?.analytics?.track("Logout")
    await signinGuestAndClearCaches()
  }

  const signupAndSignin = async (payload: SignupPayload) => {
    await signup(payload)
    window?.analytics?.track("User sign up")

    await signinUser({
      username: payload.email,
      password: payload.password,
    })
  }

  useEffect(() => {
    const localStorageAccount = localStorage.getItem("hg::cache::user")
    if (!localStorageAccount) {
      signinGuestAndClearCaches()
      return
    }

    // load account session from local storage to account context state
    const parsed: AccountSession = JSON.parse(localStorageAccount)
    if (moment().isAfter(moment(parsed.expires))) {
      signinGuestAndClearCaches()
      return
    }

    if (parsed.is_signed_up) {
      setAccountType("user")
    } else {
      setAccountType("guest")
    }
  }, [])

  return (
    <AccountContext.Provider
      value={{
        accountType,
        signinGuestAndClearCaches,
        signinUser,
        signoutAndClearCaches,
        signupAndSignin,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  return useContext<AccountContextType>(
    AccountContext as Context<AccountContextType>
  )
}
