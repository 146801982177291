import React from "react"
import { Text, TextProps } from "rebass"

export const TextBase = ({ children, ...props }: TextProps) => {
  return (
    <Text fontStyle="normal" {...(props as any)}>
      {children}
    </Text>
  )
}

export const Heading1 = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize={["24px", "48px"]}
      lineHeight={["29px", "58px"]}
      letterSpacing="-2px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const Heading2 = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize="28px"
      lineHeight="34px"
      letterSpacing="-0.02em"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const Heading3 = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize="24px"
      lineHeight="29px"
      letterSpacing="-0.01em"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const Heading4 = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize="21px"
      lineHeight="25px"
      letterSpacing="-0.01em"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}
