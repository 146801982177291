import { colors } from "./colors"

const base = {
  minHeight: "40px",
  minWidth: "80px",
  fontWeight: "700",
  fontSize: "17px",
  lineHeight: "14px",
  border: 0,
  outline: 0,
  borderRadius: "3px",
  alignText: "center",
  cursor: "pointer",
}

let buttons = {
  primary: {
    ...base,
    color: colors.secondary1,
    borderRadius: "28px",
    width: "350px",
    height: "56px",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.1)",
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
      opacity: 0.5,
    },
  },
  secondary: {
    ...base,
    color: colors.secondary1,
    borderRadius: "28px",
    width: "350px",
    height: "56px",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.1)",
    background: "#ffffff",
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
      opacity: 0.5,
    },
  },
  tertiary: {
    ...base,
    backgroundColor: colors.tertiary1,
    borderRadius: "28px",
    width: "350px",
    height: "56px",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.1)",
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
      opacity: 0.5,
    },
  },
  cta: {
    ...base,
    borderRadius: "0px",
    color: colors.white,
    backgroundColor: colors.black,
  },
}

if (colors.main.includes("gradient")) {
  buttons.primary.background = colors.main
} else {
  buttons.primary.backgroundColor = colors.main
}

export { buttons }
