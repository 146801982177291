import axios, { AxiosError, AxiosRequestConfig, Method } from "axios"
import { v4 as uuidv4 } from "uuid"

const HEAPS_BACKEND_URL =
  process.env.GATSBY_HEAPS_BACKEND_URL ||
  "https://drinks-staging.heapsapp.com/api/v0"
const API_KEY =
  process.env.GATSBY_HEAPS_API_KEY || "f02bed37-f90f-4b60-87d9-b0d93a248f46"
const API_VERSION = process.env.GATSBY_HEAPS_API_VERSION || "20191101"
const APP_ID = process.env.GATSBY_HEAPS_APP_ID || "unknown_default"

const defaultHeaders = {
  "X-Drinks-Api-Key": API_KEY,
  "X-Drinks-Version": API_VERSION,
  "X-Drinks-App-Id": APP_ID,
}

export const useAxiosRequestAnonymous = () => {
  const axiosRequest = async <T>(
    method: Method,
    url: string,
    data: object = {},
    retries = 0
  ): Promise<T> => {
    const client = axios.create({
      baseURL: HEAPS_BACKEND_URL,
    })
    try {
      const axiosResponse = await client({
        method,
        url,
        data,
        headers: defaultHeaders,
      })
      return axiosResponse.data
    } catch (err: unknown) {
      const error = err as AxiosError
      // Retry if status code is 409. Max 2 retries.
      if (error.response?.status === 409 && retries < 2) {
        return await axiosRequest(method, url, data, retries + 1)
      }
      throw error // propagate error so that it can be handled appropriately on different places in the app
    }
  }

  return axiosRequest
}

export const useAxiosRequest = () => {
  const getHeaders = () => {
    if (typeof window === "undefined") {
      return defaultHeaders
    }

    const headers: any = { ...defaultHeaders }
    const user = JSON.parse(localStorage.getItem("hg::cache::user")!)
    headers["Authorization"] = `Bearer ${user?.access_token}`
    headers["X-Drinks-Idempotency-Key"] = uuidv4()

    return headers
  }

  const client = axios.create({
    baseURL: HEAPS_BACKEND_URL,
    headers: getHeaders(),
  })

  const axiosRequest = async <T = any>(
    method: Method,
    url: string,
    data: object = {},
    retries = 0
  ): Promise<T> => {
    const config: AxiosRequestConfig = {
      method,
      url,
      data,
    }

    try {
      const axiosResponse = await client(config)
      return axiosResponse.data
    } catch (err: unknown) {
      const error = err as AxiosError

      // temp fix until we switch from 302 to 303: https://github.com/heapsapp/heapsgo-web/pull/29#issuecomment-1944032309
      if (error.response?.status === 405) {
        const axiosResponse = await client({
          method: "GET",
          url: error.response.request.responseURL,
          headers: getHeaders(),
        })
        return axiosResponse.data
      }

      // Retry if status code is 409. Max 2 retries.
      if (error.response?.status === 409 && retries < 2) {
        return await axiosRequest(method, url, data, retries + 1)
      }

      if (error.response?.status === 401) {
        // TODO: implement refresh token logic
        localStorage?.removeItem("hg::cache::user")
        localStorage?.removeItem("hg::cache::cart")
        localStorage?.removeItem("hg::cache::order")
        window.location.href = "/" // TEMP

        return new Promise((resolve) => {
          resolve({ data: {} } as any)
        })
      } else {
        throw error // propagate error so that it can be handled appropriately on different places in the app
      }
    }
  }

  return axiosRequest
}
