import React, { useState } from "react"
import { isEmpty } from "lodash"
import styled from "@emotion/styled"
import {
  ConsentManagerBuilder,
  loadPreferences,
} from "@segment/consent-manager"
import inEU from "@segment/in-eu"
import Button from "../button"
import Modal from "../modal"
import Paragraph from "../typography/paragraph"
import { useTheme } from "@emotion/react"
import { Flex } from "rebass"
import { Heading2 } from "../typography/headings"
import Checkbox from "../checkbox"

const SEGMENT_WRITE_KEY =
  process.env.GATSBY_SEGMENT_WRITE_KEY || "8ZP7MzUwIjtwMNICyKbuFIOF50MuVe4A"

export const MARKETING_AND_ANALYTICS_CATEGORIES = [
  "A/B Testing",
  "Analytics",
  "Attribution",
  "Email",
  "Enrichment",
  "Heatmaps & Recordings",
  "Raw Data",
  "Realtime Dashboards",
  "Referrals",
  "Surveys",
  "Video",
]

export const ADVERTISING_CATEGORIES = ["Advertising", "Tag Managers"]

export const FUNCTIONAL_CATEGORIES = [
  "CRM",
  "Customer Success",
  "Deep Linking",
  "Helpdesk",
  "Livechat",
  "Performance Monitoring",
  "Personalization",
  "SMS & Push Notifications",
  "Security & Fraud",
]

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  &.but-container {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column-reverse;
    .but-acc,
    .but-save {
      height: 40px;
      width: 100%;
    }
    .but-acc {
      margin: auto;
      margin-bottom: 20px;
    }
    ${(props) => props.theme.bp.mobile} {
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: row;
      flex-wrap: wrap;
      .but-acc,
      .but-save {
        height: unset;
        width: unset;
      }
      .but-acc {
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
  }
`

const Content = styled(Flex)``

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`

const CookieConsentBanner = () => {
  const [showDialog, toggleDialog] = useState(true)
  const theme = useTheme()

  return (
    <ConsentManagerBuilder
      writeKey={SEGMENT_WRITE_KEY}
      shouldRequireConsent={() => {
        const saved = loadPreferences()
        // TODO: Ask for consent again if user has previously set no
        if (saved.destinationPreferences?.Mixpanel === false) {
          return true
        }

        return isEmpty(saved) ? inEU() : false
      }}
      defaultDestinationBehavior={"imply"}
      initialPreferences={{ marketingAndAnalytics: true, analytics: true, functional: true, advertisements: false }}
      // mapCustomPreferences={(destinations, preferences) => {
      //   const prefs = destinations.reduce((acc, next) => {
      //     if (ADVERTISING_CATEGORIES.includes(next.category)) {
      //       acc[next.id] = !!preferences.advertisements
      //     } else {
      //       // Fallback to analytics
      //       acc[next.id] = !!preferences.marketingAndAnalytics
      //     }
      //     return acc
      //   }, {})
      //   return {
      //     destinationPreferences: prefs,
      //   }
      // }}
    >
      {({ isConsentRequired, preferences, setPreferences, saveConsent }) => {
        if (!isConsentRequired || !showDialog) {
          return null
        }

        return (
          <Modal show={isConsentRequired} fade={true}>
            <Modal.Body
              sx={{ position: "relative", maxHeight: "100vh !important" }}
              marginTop={[, "10%"]}
              height={["100vh !important", "500px !important"]}
              width={["100%", "500px"]}
            >
              <Content
                px={["20px", "35px"]}
                flexDirection="column"
                height={["100vh", "100%"]}
                justifyContent="center"
                width="100%"
              >
                <Heading2>We look after your data</Heading2>
                <Paragraph color={theme.colors.secondary2} my={3}>
                  We are very attentive to the need for appropriate protection
                  and responsible processing of all personal information that we
                  receive and we give data security and confidentialy high
                  priority.
                </Paragraph>
                <Paragraph color={theme.colors.secondary2}>
                  We assure full confidentiality in respect of your personal
                  data. We are responsible for personal data that is submitted
                  via the website.
                </Paragraph>
                <Row
                  className="but-container"
                  justifyContent="flex-start"
                  py={"40px"}
                >
                  <Button
                    className="but-save"
                    variant="secondary"
                    sx={{ color: theme.colors.secondary3 }}
                    onClick={() => {
                      toggleDialog(false)
                      setPreferences({
                        advertisements: preferences.advertisements || false,
                        marketingAndAnalytics: true,
                        analytics: true, // TODO: Keeping for bc - does not seem to actually existing in object
                        functional: true,
                      })
                      saveConsent(true)
                    }}
                  >
                    Save preferences
                  </Button>
                  <Button
                    className="but-acc"
                    variant="primary"
                    margin="0 0 0 20px"
                    onClick={() => {
                      toggleDialog(false)
                      setPreferences({
                        advertisements: true,
                        marketingAndAnalytics: true,
                        analytics: true,
                        functional: true,
                      })
                      saveConsent(true)
                    }}
                  >
                    Accept all
                  </Button>
                </Row>

                <Row justifyContent="flex-start">
                  <SwitchContainer>
                    <Paragraph
                      color={theme.colors.primary}
                      sx={{ fontFamily: "SFBold", marginBottom: "10px" }}
                    >
                      Necessary
                    </Paragraph>
                    <Checkbox checked={true} />
                  </SwitchContainer>
                  <SwitchContainer>
                    <Paragraph
                      color={theme.colors.primary}
                      sx={{ fontFamily: "SFBold", marginBottom: "10px" }}
                    >
                      Marketing
                    </Paragraph>
                    <Checkbox
                      checked={!!preferences.advertisements}
                      onClick={() => {
                        setPreferences({
                          advertisements: !preferences.advertisements,
                        })
                      }}
                    />
                  </SwitchContainer>
                </Row>
              </Content>
            </Modal.Body>
          </Modal>
        )
      }}
    </ConsentManagerBuilder>
  )
}

export default CookieConsentBanner
