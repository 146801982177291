import React, { useReducer } from "react"

// TODO: TS integration
export const defaultInterfaceContext = {
  collectionMethodChanged: false,
  showReceipt: false,
  setCollectionMethodChanged: (val) => {},
  setShowReceipt: () => {},
  hideDrawers: () => {},
}

export const InterfaceContext = React.createContext(defaultInterfaceContext)

export default React.createContext(defaultInterfaceContext)

export const InterfaceProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "hideDrawers":
        return {
          ...state,
          showCart: false,
          showReceipt: false,
        }

      case "showReceipt":
        return {
          ...state,
          showReceipt: true,
          showCart: false,
        }

      case "setCollectionMethodChanged":
        return {
          ...state,
          collectionMethodChanged: action.payload,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const hideDrawers = () => {
    dispatch({ type: "hideDrawers" })
  }

  const setShowReceipt = () => {
    dispatch({ type: "showReceipt" })
  }

  const setCollectionMethodChanged = (payload) => {
    dispatch({ type: "setCollectionMethodChanged", payload })
  }

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        hideDrawers,
        setShowReceipt,
        setCollectionMethodChanged,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
