import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn: "https://aceba758e0eb4e82a3ed17eb161c16ed@o1327757.ingest.sentry.io/6589392",
  environment: process.env.GATSBY_NODE_ENV,
  sampleRate: 0.7, // Adjust this value in production
  beforeSend(event) {
    // Modify the event here
    if (event.user) {
      // Don't send user's email address
      delete event.user.email
    }
    return event
  },
  ignoreErrors: [
    "TypeError: Load failed",
    "Error: Network Error",
    "TypeError: network error",
  ],
})
