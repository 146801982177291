import styled from "@emotion/styled"
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Flex } from "rebass"
import { BodyTextRegular } from "../typography/text"

const Container = styled(Flex)`
  background: ${(props) =>
    props.checked
      ? props.theme.colors.ctaMain
      : props.disabled
      ? props.theme.colors.ctaInactive
      : props.theme.colors.white};
  border: 1px solid
    ${(props) =>
      props.disabled && !props.checked
        ? props.theme.colors.ctaInactive
        : props.checked
        ? props.theme.colors.ctaMain
        : props.theme.colors.secondary2};
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  justify-content: center;
  align-items: center;
  height: 24px;
  &:hover {
    border-color: ${(props) =>
      props.disabled
        ? props.theme.colors.ctaInactive
        : props.checked
        ? props.theme.colors.ctaMain
        : props.theme.colors.ctaMain};
  }
`

const Checkbox = ({
  checked,
  disabled = false,
  onClick,
  className = "",
  text = "",
}) => (
  <Container
    style={{ cursor: "pointer" }}
    checked={checked}
    className={className}
    onClick={onClick}
    disabled={disabled}
  >
    {checked && (
      <BodyTextRegular>
        {text ? text : <FontAwesomeIcon icon={regular("check")} />}
      </BodyTextRegular>
    )}
  </Container>
)

export default Checkbox
