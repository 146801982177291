import { useTheme } from "@emotion/react"
import React from "react"
import { Flex, Box, Button as RebassButton } from "rebass"
import Spinner from "../spinner"

const Button = ({
  loading,
  children,
  variant,
  type,
  onClick,
  disabled,
  sx,
  innerStyle,
  spinnerColor,
  ...props
}) => {
  const theme = useTheme()

  const buttonVariant = theme.buttons[variant]

  const handleClick = (e) => {
    if (!loading && onClick) {
      onClick(e)
    }
  }

  return (
    <RebassButton
      sx={{
        position: "relative",
        cursor: loading ? "default" : "pointer",
        pointerEvents: loading ? "none" : "all",
        ...buttonVariant,
        ...sx,
      }}
      disabled={disabled || loading}
      onClick={handleClick}
      type={type || "button"}
      variant={buttonVariant}
      {...props}
    >
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Flex alignItems="center" css={innerStyle}>
          {children}
        </Flex>
        {loading && (
          <Flex
            alignItems="center"
            sx={{ position: "absolute", right: "25px", top: "12px" }}
          >
            <Spinner
              size="0.25"
              height="20px"
              width="20px"
              color={spinnerColor || "#ffffff"}
            />
          </Flex>
        )}
      </Flex>
    </RebassButton>
  )
}

export default Button
