import styled from "@emotion/styled"
import React from "react"
import { Flex } from "rebass"

import "./index.css"

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;

  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: ${(props) =>
        props.color ? props.color : props.theme.colors.secondary3};
    }
  }
`

const Spinner = ({ size, height, width, color, ...rest }) => (
  <Flex
    height={height ? height : "80px"}
    width={width ? width : "80px"}
    {...rest}
  >
    <SpinnerContainer
      className="lds-spinner"
      color={color}
      style={{ transform: size ? `scale(${size})` : "scale(1.0)" }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </SpinnerContainer>
  </Flex>
)

export default Spinner
