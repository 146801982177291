import React from "react"
import styled from "@emotion/styled"
import { Flex } from "rebass"

export const ModalContainer = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.fade &&
    `
  background-color: rgba(10, 10, 10, 0.5);
  `}
`

export const ModalContent = styled(Flex)`
  overflow-y: scroll;
  max-height: 100%;
  flex: 1;
  background-color: white;
  flex-direction: column;

  ${(props) => props.theme.bp.desktop} {
    border-radius: 10px;
    flex: inherit;
  }
`

const Modal = styled(Flex)`
  position: fixed;
  z-index: 1051;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  // align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.fade ? "rgba(10, 10, 10, 0.50);" : "#ffffff;"};
  overflow: auto;
`

const ModalBody = styled(Flex)`
  flex-direction: column;
  position: relative;
  background-color: white;

  ${(props) => props.theme.bp.desktop} {
    border-radius: 8px;
  }

  box-shadow: 0 7px 13px 0 rgba(60, 66, 87, 0.03),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
`

const StyledHeader = styled(Flex)`
  border-bottom: 1px solid #e3e8ee;
`

const StyledFooter = styled(Flex)`
  border-top: 1px solid #e3e8ee;
`

Modal.Body = ({ children, ...rest }) => {
  return (
    <ModalBody
      onClick={(e) => e.stopPropagation()}
      width={["100%", "450px"]}
      {...rest}
    >
      {children}
    </ModalBody>
  )
}

Modal.Content = ({ children, ...rest }) => {
  return (
    <ModalContent p={3} {...rest}>
      {children}
    </ModalContent>
  )
}

Modal.Header = ({ children, ...rest }) => {
  return (
    <StyledHeader p={3} onClick={(e) => e.stopPropagation()} {...rest}>
      {children}
    </StyledHeader>
  )
}

Modal.Footer = ({ children, ...rest }) => {
  return (
    <StyledFooter p={3} onClick={(e) => e.stopPropagation()} {...rest}>
      {children}
    </StyledFooter>
  )
}

export default Modal
