import React, { Context, createContext, useContext, useState } from "react"

export type AuthModalType =
  | "login"
  | "register"
  | "confirm-code"
  | "forgot-password"

type AuthModalsContextType = {
  visibleAuthModal: AuthModalType | undefined
  setVisibleAuthModal: (visibleAuthModal: AuthModalType | undefined) => void
}

const defaultContext: AuthModalsContextType = {
  visibleAuthModal: undefined,
  setVisibleAuthModal: () => {},
}

export const AuthModalsContext = createContext(defaultContext)

type Props = {
  children: React.ReactNode
}

export const AuthModalsProvider = ({ children }: Props) => {
  const [visibleAuthModal, setVisibleAuthModal] = useState<AuthModalType>()

  const context = {
    visibleAuthModal,
    setVisibleAuthModal,
  }

  return (
    <AuthModalsContext.Provider value={context}>
      {children}
    </AuthModalsContext.Provider>
  )
}

export const useAuthModals = () => {
  return useContext<AuthModalsContextType>(
    AuthModalsContext as Context<AuthModalsContextType>
  )
}
