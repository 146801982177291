import moment from "moment"
import { DeliveryOptionInterface } from "../models/delivery-options"
import { GetUpsellProductsPayload } from "../models/get-upsell-products-payload"
import { AuthenticateRequest } from "../models/email-authenticate-request"
import { EmailAuthenticateResponse } from "../models/email-authenticate-response"
import { ErrorItem } from "../models/error"
import { SignupInfo } from "../models/signup-info-item"
import { AddCreditCardResponse, AddCardIntentType } from "./api-types"
import { CheckAddressPayload } from "../models/check-address-payload"
import {
  EmailVerificationConfirmRequest,
  EmailVerificationRequest,
} from "../models/email-verification"
import { SignupPayload } from "../models/sign-up-form"
import { Restaurant, RestaurantListItem } from "../models/restaurant"
import { useAxiosRequest, useAxiosRequestAnonymous } from "./request"
import { AccountResponse, AccountSessionResponse } from "../models/user"
import { InitOrderRequestData } from "../models/init-order-payload"
import { CheckoutOrder, Order } from "../models/order"
import {
  AddressListSuccessResponse,
  AddressType,
  CheckAddressSuccessResponse,
} from "../models/address"
import { RecommendedProduct } from "../models/recommended-product"
import { getModifiedOpeningHours } from "./opening-hours-service"
import { UpdateOrderRequestData } from "../models/update-order-payload"

const VENUES_PATH = process.env.GATSBY_VENUES_PATH || "venues-list-all"

export const useAnonymousAPI = () => {
  const axiosRequestAnonymous = useAxiosRequestAnonymous()

  return {
    restaurants: {
      async list(payload: { longitude?: number; latitude?: number }) {
        let path = `/${VENUES_PATH}?latitude=${
          payload.latitude || 55
        }&longitude=${payload.longitude || 12}`
        const data = await axiosRequestAnonymous<{
          venues: {
            items: RestaurantListItem[]
          }
        }>("GET", path, {})
        return data.venues.items
      },

      async retrieve(
        restaurantIdOrSlug: string,
        preorderTime: string | null
      ): Promise<Restaurant> {
        let path = `/venues/${restaurantIdOrSlug}`
        if (preorderTime) {
          path += `?requested_time=${encodeURIComponent(preorderTime)}`
        }

        let data = await axiosRequestAnonymous<{ data: Restaurant }>(
          "GET",
          path
        )

        // Remodelling of opening hours
        const modifiedOpeningHours = getModifiedOpeningHours(
          data.data.legacy_opening_hours.regular
        )

        return {
          ...data.data,
          modifiedOpeningHours,
        }
      },
    },
    guest: {
      guestSignin(): Promise<AccountSessionResponse> {
        const path = "/auth/guest"
        return axiosRequestAnonymous("POST", path)
      },
    },
    auth: {
      signin(
        payload: AuthenticateRequest
      ): Promise<{ data: EmailAuthenticateResponse }> {
        const path = `/auth/username`
        return axiosRequestAnonymous("POST", path, payload)
      },

      requestEmailVerification(
        payload: EmailVerificationRequest
      ): Promise<string> {
        const path = `/email_verify`
        return axiosRequestAnonymous("POST", path, payload)
      },

      confirmEmailVerification(
        payload: EmailVerificationConfirmRequest
      ): Promise<string | ErrorItem> {
        const path = `/email_confirm`
        return axiosRequestAnonymous("POST", path, payload)
      },

      signup(payload: SignupPayload): Promise<EmailAuthenticateResponse> {
        const path = `/signup`
        return axiosRequestAnonymous("POST", path, payload)
      },

      resetPassword(payload: { email: string }): Promise<string | ErrorItem> {
        const path = `/password-recovery/reset`
        return axiosRequestAnonymous("POST", path, payload)
      },

      signupInfo(): Promise<SignupInfo> {
        const path = `/signup-info`
        return axiosRequestAnonymous("GET", path)
      },
    },
    checkout: {
      retrieveOrderByDisplayId(displayId: string) {
        const path = `/tracking-orders/${displayId}`

        return axiosRequestAnonymous<{ data: Order }>("GET", path)
      },
    },
    other: {
      // TODO
      async getCountryCode(): Promise<string> {
        const getThreeLetterCountryCode = (twoLetter: string) => {
          if (twoLetter === "NO") {
            return "nor"
          }
          if (twoLetter === "IS") {
            return "isl"
          }
          return "dnk"
        }

        const path = `/signup-info`
        try {
          const response = await axiosRequestAnonymous<any>("GET", path)
          const signup_info = response?.data?.signup_info
          const twoLetter = signup_info && signup_info[0]?.country_code
          const threeLetterCountryCode = getThreeLetterCountryCode(twoLetter)
          return threeLetterCountryCode
        } catch (e: any) {
          return "dnk"
        }
      },
    },
  }
}

export const useAPI = () => {
  const axiosRequest = useAxiosRequest()

  return {
    restaurants: {
      async retrieveTimeslotOptions(
        addressUrl: string,
        timeslots_for: moment.Moment
      ) {
        let path = addressUrl.split("v0")[1]
        // API returns all values if the timeslots_for param exists - so append it only if some other day
        if (!timeslots_for.isSame(moment(), "day")) {
          path =
            path +
            `?timeslots_for=${window.encodeURIComponent(
              timeslots_for.format("YYYY-MM-DDT00:00:00Z")
            )}`
        }
        const data = await axiosRequest("GET", path) // TODO axiosRequest return typings
        const deliveryOptionsArray: DeliveryOptionInterface[] =
          data["delivery-options"]

        return deliveryOptionsArray
      },
    },
    checkout: {
      init({ path, payload }: InitOrderRequestData): Promise<CheckoutOrder> {
        path = path.slice(7)
        return axiosRequest("POST", path, payload)
      },

      updateAddress(addressUrl: any) {
        const path = addressUrl.split("v0")[1]
        return axiosRequest("POST", path)
      },

      updateOrder({
        payload,
        path,
      }: UpdateOrderRequestData): Promise<CheckoutOrder> {
        path = path.split("v0")[1]
        return axiosRequest("POST", path, payload)
      },

      pay(paymentMethod: any, order: any) {
        const payUrl = order.order._links.pay.slice(7)
        return axiosRequest("POST", payUrl, paymentMethod)
      },

      completeSca(order: Order) {
        const path = order.intents![0]._links.confirm! // TODO links type check
        const endPath = path.split("/v0")[1]
        return axiosRequest<CheckoutOrder>("POST", endPath)
      },

      retrieveOrderById(orderId: any) {
        let path = `/me/orders/${orderId}`
        // TODO guest
        if (localStorage.getItem("hg::cache::user")) {
          const user = JSON.parse(localStorage.getItem("hg::cache::user")!)
          if (!user.is_signed_up) {
            path = path.slice(3)
          }
        }

        return axiosRequest<CheckoutOrder>("GET", path)
      },

      retrieveOrder(order: Order): Promise<CheckoutOrder> {
        let path = order._links.self.slice(7)

        // TODO guest
        if (localStorage.getItem("hg::cache::user")) {
          const user = JSON.parse(localStorage.getItem("hg::cache::user")!)
          if (user.is_signed_up) {
            path = "/me" + path
          }
        }
        return axiosRequest("GET", path)
      },

      removeFromOrder(path: string) {
        const endPath = path.split("/v0")[1]
        return axiosRequest("DELETE", endPath)
      },

      applyVoucher(path: string) {
        const endPath = path.split("/v0")[1]
        return axiosRequest("PUT", endPath)
      },

      removeVoucher(path: string) {
        const endPath = path.split("/v0")[1]
        return axiosRequest("DELETE", endPath)
      },

      redeemVoucher(code: string) {
        const path = "/redeem/promotion-code"
        return axiosRequest("POST", path, { code })
      },
    },
    addresses: {
      list(): Promise<AddressListSuccessResponse> {
        let path = `/me/addresses`
        return axiosRequest("GET", path)
      },

      create(payload: AddressType) {
        let path = `/me/addresses`
        return axiosRequest("POST", path, payload)
      },

      check({
        path,
        address,
        preOrderFor,
      }: CheckAddressPayload): Promise<CheckAddressSuccessResponse> {
        return axiosRequest("POST", path.split("v0")[1], {
          address_line: address.address_line,
          city: address.city,
          country_code: address.country_code,
          postcode: address.postcode,
          longitude: address.longitude,
          latitude: address.latitude,
          pre_order_for: preOrderFor,
        })
      },
      async delete(id: string): Promise<boolean> {
        const path = `/me/addresses/${id}`
        await axiosRequest("DELETE", path)
        return true
      },
    },
    payments: {
      cancel(cancelPath: string) {
        let path = cancelPath.split("v0")[1]
        return axiosRequest<CheckoutOrder>("POST", path)
      },

      createIntent(): Promise<AddCardIntentType> {
        const path = `/ps/create-intent`
        return axiosRequest("POST", path, {
          accept_url: "",
          cancel_url: "",
        })
      },

      addCreditCard(intentId: string): Promise<AddCreditCardResponse> {
        const path = `/ps/creditcard-add`
        return axiosRequest("POST", path, {
          gateway: "stripe", // addCreditCard always called with Stripe
          intent_id: intentId,
        })
      },

      deleteCreditCard(path: string) {
        const endPath = path.split("/v0")[1]
        return axiosRequest("DELETE", endPath)
      },
    },

    auth: {
      me(): Promise<AccountResponse> {
        return axiosRequest("GET", "/me")
      },

      signout(): Promise<string | ErrorItem> {
        const path = `/signout`
        return axiosRequest("POST", path)
      },
    },

    recommendedProducts: {
      async getUpsellProducts(
        payload: GetUpsellProductsPayload
      ): Promise<RecommendedProduct[]> {
        const { shopId, ...rest } = payload
        const path = `/shops/${shopId}/recommended-products`
        const data = await axiosRequest("POST", path, rest)
        return data["recommended-products"]
      },
    },
  }
}
