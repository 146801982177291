import React, { useReducer } from "react"
import { Cart } from "../models/cart"
import { CollectionMethod } from "../models/collection-method"
import { CollectionMethodIdentifiers } from "../util/collection-method-identifiers"

// TODO ts integration
type StoreContextType = {
  deliveryAddress: any
  preOrder: string | null
  setCollectionMethod: (collectionMethod: CollectionMethod) => void
  collectionMethod: CollectionMethod
  setDeliveryAddress: any
  clearCart: any
  cart: any
  addressEstimation: any
  setPreOrder: any
  setAddressEstimation: any
  initialCollectionMethodChosen: CollectionMethod | undefined
  setInitialCollectionMethodChosen: (val: CollectionMethod) => void
  updateCartItems: (val: Cart) => void
}

export const defaultStoreContext = {
  adding: false,
  preOrder: null,
  deliveryAddress: null,
  cart: { id: "", items: [] },
  collectionMethod: CollectionMethodIdentifiers.pickup,
  shopDeliveryAddresses: [],
  addressEstimation: undefined,
  initialCollectionMethodChosen: undefined,
  updateCartItems: () => {},
  clearCart: () => {},
  getOrder: async () => {},
  addAddress: () => {},
  setPreOrder: () => {},
  setDeliveryAddress: () => {},
  setCollectionMethod: (collectionMethod: CollectionMethod) => {},
  setShopDeliveryAddresses: (payload) => {},
  setAddressEstimation: (payload) => {},
  setInitialCollectionMethodChosen: (val: CollectionMethod) => {},
}

const StoreContext = React.createContext<StoreContextType>(defaultStoreContext)
export default StoreContext

export const allCartItems = (categories) => {
  let allItems = []

  categories?.forEach((c) => {
    c.products.forEach((p) => {
      allItems.push({
        id: `${p.id}`,
        category_id: c.id,
        addition_options:
          p?.addition_options?.map((option) => {
            const options = option.additions.map((addition) => {
              return {
                ...addition,
                selected: addition.preselected,
                disabled: addition.disabled,
              }
            })

            return {
              ...option,
              additions: options,
            }
          }) || [],
        quantity: 0,
        index: 0,
        price: p?.price,
        price_delivery: p?.price_delivery,
        title: p?.title,
        image_url: p?.image_url,
      })
    })
  })

  return allItems
}

const reducer = (state, action) => {
  switch (action.type) {
    case "loadingRestaurant": {
      return {
        ...state,
        loadingRestaurant: !state.loadingRestaurant,
      }
    }

    case "setPreOrder": {
      return {
        ...state,
        preOrder: action.payload,
      }
    }

    case "setCollectionMethod": {
      return {
        ...state,
        collectionMethod: action.payload,
      }
    }

    case "setShopDeliveryAddresses": {
      return {
        ...state,
        shopDeliveryAddresses: action.payload,
      }
    }

    case "setAddressEstimation": {
      return {
        ...state,
        addressEstimation: action.payload,
      }
    }

    case "setDeliveryAddress": {
      return {
        ...state,
        deliveryAddress: action.payload,
      }
    }

    case "setCart":
      if (localStorage) {
        localStorage.setItem("hg::cache::cart", JSON.stringify(action.payload))
      }

      return {
        ...state,
        cart: action.payload,
      }

    case "setInitialCollectionMethodChosen": {
      return {
        ...state,
        initialCollectionMethodChosen: action.payload,
      }
    }

    default:
      return state
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStoreContext)

  const updateCartItems = (cart: Cart) => {
    dispatch({ type: "setCart", payload: cart })
  }

  const setPreOrder = (time = null) => {
    dispatch({ type: "setPreOrder", payload: time })
  }

  const setCollectionMethod = (collectionMethod: CollectionMethod) => {
    dispatch({ type: "setCollectionMethod", payload: collectionMethod })
  }

  const setDeliveryAddress = (addr) => {
    dispatch({ type: "setDeliveryAddress", payload: addr })
  }

  const setShopDeliveryAddresses = (payload) => {
    dispatch({ type: "setShopDeliveryAddresses", payload })
  }

  const setAddressEstimation = (payload) => {
    dispatch({ type: "setAddressEstimation", payload })
  }

  const setInitialCollectionMethodChosen = (payload: CollectionMethod) => {
    dispatch({ type: "setInitialCollectionMethodChosen", payload })
  }

  const addAddress = (addr) => {
    dispatch({
      type: "setDeliveryAddress",
      payload: [addr, ...state.shopDeliveryAddresses],
    })
  }

  const clearCart = () => {
    if (!localStorage?.getItem("hg::cache::cart")) {
      return
    }
    dispatch({ type: "setCart", payload: { id: "", items: [] } })
    dispatch({ type: "setPreOrder", payload: null })
    dispatch({
      type: "setCollectionMethod",
      payload: CollectionMethodIdentifiers.pickup,
    })
    dispatch({ type: "setDeliveryAddress", payload: null })
  }

  return (
    <StoreContext.Provider
      value={{
        ...state,
        updateCartItems,
        clearCart,
        setPreOrder,
        setCollectionMethod,
        setDeliveryAddress,
        addAddress,
        setShopDeliveryAddresses,
        setAddressEstimation,
        setInitialCollectionMethodChosen,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
