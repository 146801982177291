import React from "react"
import { TextProps } from "rebass"
import { TextBase } from "./headings"

export const LegalBodyTextRegular = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter400"
      fontSize="14px"
      lineHeight="24px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodyTextRegular = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter400"
      fontSize="14px"
      lineHeight="17px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodyTextSemibold = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize="14px"
      lineHeight="17px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodyTextMedium = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter500"
      fontSize="14px"
      lineHeight="17px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodySubtextNormal = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter400"
      fontSize="12px"
      lineHeight="15px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodySubtextSemibold = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter600"
      fontSize="12px"
      lineHeight="15px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}

export const BodySubtextWide = ({ children, ...props }: TextProps) => {
  return (
    <TextBase
      fontFamily="Inter400"
      fontSize="12px"
      lineHeight="15px"
      letterSpacing="1px"
      {...(props as any)}
    >
      {children}
    </TextBase>
  )
}
