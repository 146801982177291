import { ThemeProvider as Provider, css } from "@emotion/react"
import React from "react"
import { buttons } from "./buttons"
import { colors } from "./colors"
import { shadows } from "./shadow"

const MOBILE_BREAKPOINT_MAX = 767
const DESKTOP_BREAKPOINT_MIN = 768
const HD_BREAKPOINT_MIN = 1201

export const mobileContainer = css`
  @media (min-width: ${DESKTOP_BREAKPOINT_MIN}px) {
    display: none !important;
  }
`
export const desktopContainer = css`
  @media (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    display: none !important;
  }
`

const theme = {
  bp: {
    mobile: `@media (max-width: ${MOBILE_BREAKPOINT_MAX}px)`,
    // TODO responsive: add for tablet
    desktop: `@media (min-width: ${DESKTOP_BREAKPOINT_MIN}px)`,
    hd: `@media (min-width: ${HD_BREAKPOINT_MIN}px)`,
  },
  breakpoints: [`${DESKTOP_BREAKPOINT_MIN}px`, "960px"], // TODO responsive: make sure 960 is not used and remove
  heights: [30, 60, 120, 240],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors,
  buttons,
  shadows,
}

export const ThemeProvider = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
)
