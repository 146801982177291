import React from "react"
import { StoreProvider } from "./src/context/store-context"
import { InterfaceProvider } from "./src/context/interface-context"
import { ThemeProvider } from "./src/theme"
import CookieConsentBanner from "./src/components/cookie"
import { withPrefix, Script } from "gatsby"
import QueryRootElement from "./query-root-element"
import { AuthModalsProvider } from "./src/context/AuthModalsContext"
import { AccountProvider } from "./src/context/AccountContext"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <QueryRootElement>
      <ThemeProvider>
        <AuthModalsProvider>
          <InterfaceProvider>
            <StoreProvider>
              {process.env.GATSBY_MESSENGER_SCRIPT_NAME && (
                <Script
                  src={withPrefix(process.env.GATSBY_MESSENGER_SCRIPT_NAME)}
                  type="text/javascript"
                />
              )}
              <AccountProvider>{element}</AccountProvider>
              <CookieConsentBanner />
            </StoreProvider>
          </InterfaceProvider>
        </AuthModalsProvider>
      </ThemeProvider>
    </QueryRootElement>
  )
}
