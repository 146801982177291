exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[country-code]-restaurants-[id]-checkout-tsx": () => import("./../../../src/pages/[country-code]/restaurants/[id]/checkout.tsx" /* webpackChunkName: "component---src-pages-[country-code]-restaurants-[id]-checkout-tsx" */),
  "component---src-pages-[country-code]-restaurants-[id]-tsx": () => import("./../../../src/pages/[country-code]/restaurants/[id].tsx" /* webpackChunkName: "component---src-pages-[country-code]-restaurants-[id]-tsx" */),
  "component---src-pages-[country-code]-restaurants-tsx": () => import("./../../../src/pages/[country-code]/restaurants.tsx" /* webpackChunkName: "component---src-pages-[country-code]-restaurants-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nav-tsx": () => import("./../../../src/pages/nav.tsx" /* webpackChunkName: "component---src-pages-nav-tsx" */),
  "component---src-pages-order-status-tsx": () => import("./../../../src/pages/order-status.tsx" /* webpackChunkName: "component---src-pages-order-status-tsx" */),
  "component---src-pages-restaurants-tsx": () => import("./../../../src/pages/restaurants.tsx" /* webpackChunkName: "component---src-pages-restaurants-tsx" */),
  "component---src-pages-t-display-id-tsx": () => import("./../../../src/pages/t/[displayId].tsx" /* webpackChunkName: "component---src-pages-t-display-id-tsx" */),
  "component---src-templates-restaurant-template-tsx": () => import("./../../../src/templates/restaurantTemplate.tsx" /* webpackChunkName: "component---src-templates-restaurant-template-tsx" */)
}

