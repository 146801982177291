type CollectionMethodIdentifiersType = {
  courier: "courier"
  pickup: "pickup"
  eatIn: "eat-in"
}

export const CollectionMethodIdentifiers: CollectionMethodIdentifiersType = {
  courier: "courier",
  pickup: "pickup",
  eatIn: "eat-in",
}
