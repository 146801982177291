import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAPI, useAnonymousAPI } from "./api"
import { DeliveryOptionInterface } from "../models/delivery-options"
import moment from "moment"
import { Product } from "../models/product"
import { CollectionMethod } from "../models/collection-method"

export const cacheKeys = {
  restaurants: "restaurants",
  restaurant: "restaurant",
  user: "user",
  addresses: "addresses",
  timeslots: "timeslots",
  recommendedProducts: "recommendedProducts",
  signup_info: "signup_info",
  order: "order",
}

export const useFetchRestaurant = (
  idOrSlug: string | undefined,
  preorderTime: string | null
) => {
  const anonymousAPI = useAnonymousAPI()

  return useQuery({
    queryKey: [cacheKeys.restaurant, idOrSlug, preorderTime],
    queryFn: async () =>
      await anonymousAPI.restaurants.retrieve(idOrSlug!, preorderTime),
    enabled: !!idOrSlug,
  })
}

export const useFetchRestaurants = () => {
  const anonymousAPI = useAnonymousAPI()

  return useQuery({
    queryKey: [cacheKeys.restaurants],
    queryFn: async () => await anonymousAPI.restaurants.list({}),
  })
}

export const useRemoveProduct = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation<any, any, string>({
    mutationFn: api.checkout.removeFromOrder,
    onSuccess: async () => {
      queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}

export const useFetchAddresses = (enabled: boolean) => {
  const api = useAPI()

  return useQuery({
    queryKey: [cacheKeys.addresses],
    queryFn: async () => await api.addresses.list(),
    refetchOnWindowFocus: false,
    enabled,
  })
}

export const useDeleteAddress = () => {
  const api = useAPI()

  const queryClient = useQueryClient()
  return useMutation(api.addresses.delete, {
    onSuccess: async () => {
      queryClient.refetchQueries([cacheKeys.addresses])
    },
  })
}

export const useCreateAddress = () => {
  const api = useAPI()

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.addresses.create,
    onSuccess: async () => {
      queryClient.refetchQueries([cacheKeys.addresses])
    },
  })
}

export const useCheckAddressAvailability = () => {
  const api = useAPI()

  return useMutation(api.addresses.check)
}

export const useGetTimeslotOptions = (url: string, date: string) => {
  const api = useAPI()

  return useQuery<DeliveryOptionInterface[], any>({
    queryKey: [cacheKeys.timeslots, date],
    queryFn: async () =>
      await api.restaurants.retrieveTimeslotOptions(url, moment(date)),
    refetchOnWindowFocus: false,
  })
}

export const useAccountType = () => {
  // TODO
  // once /me can be used for both guest and user, make use of that call in this hook
  // in order to determine account type
  // just refetch this query in `onSuccess` of `useGuestSignin`, `useSignin` and `useSignout`
  // in order to have fresh info all the time
  // call this hook in AccountContext.tsx
}

export const useUser = () => {
  const api = useAPI()

  return useQuery({
    queryKey: [cacheKeys.user],
    queryFn: async () => {
      const { data: account } = await api.auth.me()
      return account
    },
    refetchOnWindowFocus: false,
  })
}

export const useGuestSignin = () => {
  const api = useAnonymousAPI()

  return useMutation({
    mutationFn: api.guest.guestSignin,
  })
}

export const useSignin = () => {
  const api = useAnonymousAPI()

  return useMutation({
    mutationFn: api.auth.signin,
  })
}

export const useSignup = () => {
  const api = useAnonymousAPI()

  return useMutation({
    mutationFn: api.auth.signup,
  })
}

export const useSignout = () => {
  const api = useAPI()

  return useMutation<any, any>({
    mutationFn: api.auth.signout,
  })
}

export const useRecommendedProducts = (
  shopId: string | undefined,
  collectionMethod: CollectionMethod,
  data: Product[],
  isOpen: boolean
) => {
  const api = useAPI()
  const getPayload = () => ({
    shopId: shopId || "",
    collection_method: collectionMethod,
    products: data.map((p) => ({
      id: p.id,
      addition_options: (p.addition_options || []).map((ao) => ({
        id: ao.id,
        additions: ao.additions.map((a) => ({
          id: a.id,
        })),
      })),
    })),
  })

  return useQuery({
    queryKey: [cacheKeys.recommendedProducts, shopId, getPayload()],
    queryFn: async () => {
      const recommendedProducts =
        await api.recommendedProducts.getUpsellProducts(getPayload())
      return recommendedProducts
    },
    refetchOnWindowFocus: false,
    enabled: isOpen && !!shopId,
  })
}

export const useSignupInfo = () => {
  const anonymousAPI = useAnonymousAPI()

  return useQuery({
    queryKey: [cacheKeys.signup_info],
    queryFn: async () => await anonymousAPI.auth.signupInfo(),
    refetchOnWindowFocus: false,
  })
}

export const useGetOrder = (id?: string) => {
  const api = useAPI()

  return useQuery({
    queryKey: [cacheKeys.order, id],
    queryFn: async () => await api.checkout.retrieveOrderById(id),
    refetchOnWindowFocus: true,
    enabled: !!id,
  })
}

export const useInitCheckout = () => {
  const api = useAPI()

  return useMutation({
    mutationFn: api.checkout.init,
  })
}

export const useUpdateOrder = (id?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.checkout.updateOrder,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, id])
    },
  })
}

export const useCancelPayment = (id?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.payments.cancel,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, id])
    },
  })
}

export const useRemoveVoucherFromOrder = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.checkout.removeVoucher,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}

export const useApplyVoucherForOrder = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.checkout.applyVoucher,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}

export const useRedeemVoucherFromCode = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.checkout.redeemVoucher,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}

export const useDeleteCreditCard = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.payments.deleteCreditCard,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}

export const useAddCreditCard = (orderId?: string) => {
  const api = useAPI()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.payments.addCreditCard,
    onSuccess: async () => {
      await queryClient.refetchQueries([cacheKeys.order, orderId])
    },
  })
}
